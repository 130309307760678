.canvas{
  background-color:#ffffff;
  color:#444444;
}
.rectangles{
  .label{
    padding:10px;
    font-size:20px;
  }

}
.tree{
  border:2px solid green;  
}
.forest{
background: rgb(85,124,33);
background: linear-gradient(360deg, rgba(85,124,33,1) 0%, rgba(144,125,72,1) 38%, rgba(107,164,210,1)  43%, rgba(107,164,210,1) 78%, rgba(88,155,232,1) 100%);
}
.coast{
  background-color:navy;
}
.chaos{
  .stager{
    margin-top:0px;
    margin-left:auto;
    margin-right:auto;
  }
}