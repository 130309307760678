.home{
	width: 100wh;
	height: 100vh;
  
  color: #fff;
  background-color:#07223D;

  @-webkit-keyframes Gradient {
    0% {
      background-position: 0% 50%
    }
    50% {
      background-position: 100% 50%
    }
    100% {
      background-position: 0% 50%
    }
  }
  @-moz-keyframes Gradient {
    0% {
      background-position: 0% 50%
    }
    50% {
      background-position: 100% 50%
    }
    100% {
      background-position: 0% 50%
    }
  }
  @keyframes Gradient {
    0% {
      background-position: 0% 50%
    }
    50% {
      background-position: 100% 50%
    }
    100% {
      background-position: 0% 50%
    }
  }
  h1,
  h6 {
    font-family: 'Open Sans';
    font-weight: 300;
    text-align: center;
    position: absolute;
    top: 45%;
    right: 0;
    left: 0;
  } 
  .social {
    animation-name:showSocial;
    animation-delay:0s;
    animation-duration:2s;
    animation-iteration-count:1; 
    left:40%; 
    margin: 20px auto;
  }
  h1{
    color:#aaa;
  }
  .social h4 {
    margin: 20px 0;
    color: #eee;
    font-size: 30px;
    text-align: center;
    text-shadow: -1px 0px 0 #444;
    filter: dropshadow(color=#444, offx=0, offy=1);

  }
  .social .links{
    
    width:25%;
    margin-left: auto;
    margin-right: auto;
  }
  @media screen and (max-width: 768px) {
    .social .links{
     width:60%;
    }
  }
  .social a {
    margin-right: 30px;
    text-align: center;
    color: #aaa;
    text-decoration: none;
    font-family: 'Roboto', sans-serif;
    font-size: 40px;
    font-weight: 300;
    text-shadow: -2px 0px 0 #374D63;
    filter: dropshadow(color=#374D63, offx=1, offy=1);
  }
  .social a i:hover{
    cursor: pointer;
  }
  .social a:last-child {
    margin: 0;
  }
  .text {
    margin: auto;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    //width: 700px;
    height: 60px;
  }
  h2 {
    position: relative;
    font-size: 48px;
    padding-left: 30px;
    height: 60px;
    color: #FFF;
    width: auto;
    font-weight: 400;  

  }
  .rw-sentence{
    font-family: 'Rokkitt';
    position: relative;
    font-size: 24px;
    color: #ffffff;
    width: auto;
    font-weight: 400;  
    padding:10px 30px;
    margin-right: auto;
    margin-left: auto;
  }
  .rw-words{
    display: inline;
  }
  .rw-words-1 span i {
    color: #FC4349;
    font-size: 38px;
  }

  .rw-words-1 span{
    position: absolute;
    opacity: 0;
    margin-left: 10px;
    overflow: hidden;
    -webkit-animation: rotateWord 14s linear 0s;
    -moz-animation: rotateWord 14s linear 0s;
    -o-animation: rotateWord 14s linear 0s;
    -ms-animation: rotateWord 14s linear 0s;
    animation: rotateWord 14s linear 0s;
    animation-iteration-count: infinite;
  }
  .rw-words-1 span:nth-child(2) { 
    -webkit-animation-delay: 2s; 
    -moz-animation-delay: 2s; 
    -o-animation-delay: 2s; 
    -ms-animation-delay: 2s; 
    animation-delay: 2s; 
    color: #fff;
  }
  .rw-words-1 span:nth-child(3) { 
    -webkit-animation-delay: 4s; 
    -moz-animation-delay: 4s; 
    -o-animation-delay: 4s; 
    -ms-animation-delay: 4s; 
    animation-delay: 4s; 
    color: #fff;	
  }
  .rw-words-1 span:nth-child(4) { 
      -webkit-animation-delay: 6s;
    -moz-animation-delay: 6s; 
    -o-animation-delay: 6s; 
    -ms-animation-delay: 6s; 
    animation-delay: 6s; 
  }
  .rw-words-1 span:nth-child(5) { 
      -webkit-animation-delay: 8s; 
    -moz-animation-delay: 8s; 
    -o-animation-delay: 8s; 
    -ms-animation-delay: 8s; 
    animation-delay: 8s; 
  }
  .rw-words-1 span:nth-child(6) { 
      -webkit-animation-delay: 10s; 
    -moz-animation-delay: 10s; 
    -o-animation-delay: 10s; 
    -ms-animation-delay: 10s; 
    animation-delay: 10s; 
  }
  @-webkit-keyframes rotateWord {
    0% { opacity: 0; }
    2% { opacity: 0; -webkit-transform: translateY(-30px); }
    5% { opacity: 1; -webkit-transform: translateY(0px);}
    17% { opacity: 1; -webkit-transform: translateY(0px); }
    20% { opacity: 0; -webkit-transform: translateY(30px); }
    80% { opacity: 0; }
    100% { opacity: 0; }
  }
  @-moz-keyframes rotateWord {
    0% { opacity: 0; }
    2% { opacity: 0; -moz-transform: translateY(-30px); }
    5% { opacity: 1; -moz-transform: translateY(0px);}
    17% { opacity: 1; -moz-transform: translateY(0px); }
    20% { opacity: 0; -moz-transform: translateY(30px); }
    80% { opacity: 0; }
    100% { opacity: 0; }
  }
  @-o-keyframes rotateWord {
    0% { opacity: 0; }
    2% { opacity: 0; -o-transform: translateY(-30px); }
    5% { opacity: 1; -o-transform: translateY(0px);}
    17% { opacity: 1; -o-transform: translateY(0px); }
    20% { opacity: 0; -o-transform: translateY(30px); }
    80% { opacity: 0; }
    100% { opacity: 0; }
  }
  @-ms-keyframes rotateWord {
    0% { opacity: 0; }
    2% { opacity: 0; -ms-transform: translateY(-30px); }
    5% { opacity: 1; -ms-transform: translateY(0px);}
    17% { opacity: 1; -ms-transform: translateY(0px); }
    20% { opacity: 0; -ms-transform: translateY(30px); }
    80% { opacity: 0; }
    100% { opacity: 0; }
  }
  @keyframes rotateWord {
    0% { opacity: 0; }
    2% { opacity: 0; transform: translateY(-30px); }
    5% { opacity: 1; transform: translateY(0px);}
    17% { opacity: 1; transform: translateY(0px); }
    20% { opacity: 0; transform: translateY(30px); }
    80% { opacity: 0; }
    100% { opacity: 0; }
  }
  @media screen and (max-width: 768px){
    .rw-sentence { 
      font-size: 20px; 
      width: 100%;
    }
  }

}
