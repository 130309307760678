
@media(max-width:992px){
  .navbar-fixed{
    z-index:9999;
  }
}
.menu{
  nav{
    color: #fff;
    background: linear-gradient(-45deg, #859BAB, #1C2E3B, #07223D, #64D9C7, #181C1F);
    background-size: 400% 100%;
    animation: Gradient 60s ease infinite;
    a.brand-logo{
      font-weight: 500;
      font-size:16px;
    }
    ul{
      li{
        list-style-type: none;
      }
    }
    a a.navlink{
      background-color: rgba(0, 0, 0, 0.0) !important;
    }

  }
  .sidenav{
    padding-top:60px;
    li>a{
      font-size:20px;
    }
  }
  @media(min-width: 1000px){
    nav{

      a.brand-logo{
        font-size:30px;
      }    
    }
  }
}



