#header{
  .navbar-default {
    background-color: #ffffff;
    border-color: #fafafa;
  } 
  .navbar-brand > img {
      display: inline;
      vertical-align: text-top;
      color:#000000;
  }
  #mainNav > .container{
    margin: 5px 0px;
    ul.nav{
      margin-left:10px;
    }
    width:100%;

  }
  @media screen and (max-width: 600px) {
    #submenu {
      margin-left:0px !important;
    }
  }

  svg {
    clip-rule:evenodd;
    stroke-linecap:round;
    stroke-linejoin:round;
    stroke-miterlimit:1.5;
  }
  @media screen and (min-width: 1024px) {
    svg{
      width:20%;
    }
  }
  @media screen and (max-width: 1023px) {
    svg{
      width:70%;
    }
  }
  .letter, .long-letter, .short-letter {
    fill: none;
    stroke: rgba(100,100,100,0.1);;
    stroke-width: 14px;
    animation: draw-letters  4s 1 ease-in-out forwards; 
  }
  @keyframes draw-letters {
    to{
      stroke:#1C2E3B;
      stroke-dashoffset:0;
    }
  }
  .letter {
    stroke-dasharray: 110;
    stroke-dashoffset: 110;
  }
  .short-letter{
    stroke-dasharray: 80;
    stroke-dashoffset: 80;
  }
  .long-letter{
    stroke-dasharray: 150;
    stroke-dashoffset: 150;
  }
  #c1-a-path{
    clip-path: url(#c1-a-mask);
    -webkit-clip-path: url(#c1-a-mask);
  }
  #o1-a-path{
    clip-path: url(#o1-a-mask);
    -webkit-clip-path: url(#o1-a-mask);
  }
  #n1-a-path{
    clip-path: url(#n1-a-mask);
    -webkit-clip-path: url(#n1-a-mask);
  }
  #n1-b-path{
    clip-path: url(#n1-b-mask);
    -webkit-clip-path: url(#n1-b-mask);
  }
  #n2-a-path{
    clip-path: url(#n2-a-mask);
    -webkit-clip-path: url(#n2-a-mask);
  }
  #n2-b-path{
    clip-path: url(#n2-b-mask);
    -webkit-clip-path: url(#n2-b-mask);
  }
  #e1-a-path{
    clip-path: url(#e1-a-mask);
    -webkit-clip-path: url(#e1-a-mask);
  }
  #c2-a-path{
    clip-path: url(#c2-a-mask);
    -webkit-clip-path: url(#c2-a-mask);
  }
  #t1-a-path{
    clip-path: url(#t1-a-mask);
    -webkit-clip-path: url(#t1-a-mask);
  }
  #t1-b-path{
    clip-path: url(#t1-b-mask);
    -webkit-clip-path: url(#t1-b-mask);
  }
  #e2-a-path{
    clip-path: url(#e2-a-mask);
    -webkit-clip-path: url(#e2-a-mask);
  }
  #d1-a-path{
    clip-path: url(#d1-a-mask);
    -webkit-clip-path: url(#d1-a-mask);
  }
  #d1-b-path{
    clip-path: url(#d1-b-mask);
    -webkit-clip-path: url(#d1-b-mask);
  }
  #r1-a-path{
    clip-path: url(#r1-a-mask);
    -webkit-clip-path: url(#r1-a-mask);
  }
  #r1-b-path{
    clip-path: url(#r1-b-mask);
    -webkit-clip-path: url(#r1-b-mask);
  }
  #r1-c-path{
    clip-path: url(#r1-c-mask);
    -webkit-clip-path: url(#r1-c-mask);
  }
  #e3-a-path{
    clip-path: url(#e3-a-mask);
    -webkit-clip-path: url(#e3-a-mask);
  }
  #a1-a-path{
    clip-path: url(#a1-a-mask);
    -webkit-clip-path: url(#a1-a-mask);
  }
  #a1-b-path{
    clip-path: url(#a1-b-mask);
    -webkit-clip-path: url(#a1-b-mask);
  }
  #s1-a-path{
    clip-path: url(#s1-a-mask);
    -webkit-clip-path: url(#s1-a-mask);
  }
  #o2-a-path{
    clip-path: url(#o2-a-mask);
    -webkit-clip-path: url(#o2-a-mask);
  }
  #n3-a-path{
    clip-path: url(#n3-a-mask);
    -webkit-clip-path: url(#n3-a-mask);
  }
  #n3-b-path{
    clip-path: url(#n3-b-mask);
    -webkit-clip-path: url(#n3-b-mask);
  }
  #i1-a-path{
    clip-path: url(#i1-a-mask);
    -webkit-clip-path: url(#i1-a-mask);
  }
  #i1-b-path{
    clip-path: url(#i1-b-mask);
    -webkit-clip-path: url(#i1-b-mask);
  }
  #n4-a-path{
    clip-path: url(#n4-a-mask);
    -webkit-clip-path: url(#n4-a-mask);
  }
  #n4-b-path{
    clip-path: url(#n4-b-mask);
    -webkit-clip-path: url(#n4-b-mask);
  }
  #g1-a-path{
    clip-path: url(#g1-a-mask);
    -webkit-clip-path: url(#g1-a-mask);
  }
  #g1-b-path{
    clip-path: url(#g1-b-mask);
    -webkit-clip-path: url(#g1-b-mask);
  }
  

/*
  @media screen and (max-width: 768px) {
    .letter {
      stroke-dasharray: 220;
      stroke-dashoffset: 220;
    }
    .short-letter{
      stroke-dasharray: 160;
      stroke-dashoffset: 160;
    }
    .long-letter{
      stroke-dasharray:300;
      stroke-dashoffset: 300;
    }
  } 
  */
}

#footer{
  padding: 20px;
  background-color:#419DC1;
  .footer-container{
    a{
      color:#fff;
    }
    width:75%;
    margin:auto;
    margin-bottom:50px;
    display: flex; 
    flex-wrap: wrap;
    flex-direction:row; 
    .footer-item-copyright{
      margin:auto; 
    }
  }
}
@media screen and (max-width: 768px) {
  #footer{
    width:100%;
    padding:28px;
    margin:-1px 0px;
    .footer-container{
      width:100%;
      display: flex; 
      flex-wrap: wrap;
      //flex-direction:column-reverse;
      align-items: center;
      .footer-item-copyright{
        align-items:center;
        margin-bottom:30px;
        width:90%;
        margin:auto;

      }
    }
  }
} 